// Styles
// // CoreUI Icons Set
// @import '~@coreui/icons/css/coreui-icons.css';
// // Import Font Awesome Icons Set
// @import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
//Import Main styles for this application
@import './scss/style.scss';

