// Here you can add other styles
.imgBorder {
	border-radius: 3px;
	padding: 5px 5px;
	margin-top: 0;
	width: unset;
	display: inline-block;
	height: unset;
}

.imgContainer {
	width: 140px;
	height: 140px;
	background: rgb(150, 150, 150);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.searchInput {
	border-radius: 5px;
}

.imgContainer img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.uploadButton {
	position: absolute;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.erTableData {
	vertical-align: middle;
}

.erTableData td {
	vertical-align: middle;
}

.erBorder {
	border: 1px solid #e4e7ea;
	border-radius: 0.25rem;
	padding: 5px;
}

.sessionPopupContainer {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 500px;
	height: 500px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99999;
	background-color: white;
	border: 1px solid lightgray;
	border-radius: 5px;

	button {
		margin: 0 10px;
	}
}

.app-footer {
	display: flex;
	justify-content: space-between;
	background: #9CA860;
}

.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	//text-decoration: underline;
	display: inline;
	margin: 0;
	padding: 0;
}

.link-button:hover,
.link-button:focus {
	text-decoration: none;
}

@media (max-width: 992px) {
	.sm-p0 {
		padding: 0;
	}

	.sm-pt0 {
		padding-top: 0;
	}
}

// @media (min-width: 1900px) {
// 	.container {
// 		// 70%
// 		max-width: 1350px;
// 	}

// 	.card-body {
// 		padding: 1.75rem;
// 	}
// }

//retina displays
@media (min-width: 2000px) {
	.container {
		max-width: 1700px;
	}

	.card-body {
		padding: 2.5rem;
	}
}


//@media (min-width: 992px) {
//  .container {
//    max-width: 1700px;
//  }
//  }
.main {
	//background-image: url("/background.jpg");
	background-color: white;
}

.card {
	border: none;
}

.leaflet-container {
	height: 100%;
	width: 100%;
}

.project-map {
	height: 250px;
}

.public-project-map {
	//padding-bottom: 50px;
	//height: 100%;
	padding-bottom: 80%;
	width: 100%;
}

.picker-map {
	height: 600px;
}

.dashboard-map {
	height: 380px;
}


.form-control.is-invalid {
	background-image: none;
	padding-right: 5px;
}

.full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}


//.planet {
//  width: auto;
//  height: 100%;
//  @media (max-width: 720px) {
//    display: none;
//    width: 500px;
//    height: 500px;
//
//  }
//}
//
//.planetMobile {
//  display: none;
//  @media (max-width: 720px) {
//    width: 100%;
//    height: auto;
//    display: flex;
//    justify-content: center;
//  }
//}

.planet {
	height: auto;
	width: 100%;
	padding-top: 20px;

	@media (max-width: 720px) {
		display: none;

	}
}

.planet-mobile{
	display: none;
	@media (max-width: 720px) {
		width: 100%;
		height: auto;
		display: flex;
		justify-content: center;
	}
}

.header1 {
	height: 600px;
	@media (max-width: 720px) {
		height: 900px;
	}
	@media (max-width: 540px) {
		height: 800px;
	}
}

.header2 {
	display: flex;
	@media (max-width: 720px) {
		display: block;
	}

	@media (min-width: 2000px) {
		height: 950px;
	}
}

.header3 {

	@media (max-width: 540px) {
		height: 773px;
	}
}

.header4 {
	display: flex;
	height: 483px;
	@media (max-width: 720px) {
		display: block;
		height:777px;
	}
}

.header5 {
	display: flex;
	justify-content: center;
	@media (max-width: 720px) {
		display: block;
		height: 600px;
	}
}

.header6 {
	display: flex;
	justify-content: space-evenly;
	@media (max-width: 720px) {
		display: block;
		height: 640px;
	}
}

.mapTitle {
	display: flex;
	align-content: center;
	justify-content: center;
	font-family: Amiko;
	font-size: 36px;
	font-weight: 600;
	line-height: 54px;
	letter-spacing: 0;
	text-align: left;
	color: #952168;

}

.hands {
	width: 100%;
	height: auto;
	@media (min-width: 1200px) {
		width: 558px;
	}
}

.volunteering {
	height: 320px;
	width: auto;
	padding-top: 20px;
}

.computer {
	height: auto  ;
	width: 100%;
	padding-top: 20px;

	@media (min-width: 1200px) {
		height:346px;

	}
	@media (max-width: 720px) {
		display: none;

	}
}

.computer-mobile{
	display: none;
	@media (max-width: 720px) {
		width: auto;
		height: 285px;
		display: flex;
		justify-content: center;
	}
}

.search {
	width: 19.5px;
	height: 19.5px;
	position: relative;
	top: -14px;
	z-index: 1;
	left: 30px;
}

#inputID::placeholder {
	color: #D0D0D0;
	opacity: 1;
}

.searchBox {
	font-family: Amiko;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0;
	text-align: left;

}

.subtitle {
	font-family: Amiko;
	font-size: 32px;
	font-weight: 700;
	line-height: 38px;
	letter-spacing: 0;
	text-align: left;
	color: #75994C;
	@media (max-width: 720px) {
		font-family: Amiko;
		font-size: 24px;
		font-weight: 700;
		line-height: 29px;
		letter-spacing: 0;
		text-align: center;

	}
}

.inventory {
	@media (max-width: 960px) {
		width: 427px;
	}
}


.subtitle-blue {
	font-family: Amiko;
	font-size: 32px;
	font-weight: 700;
	line-height: 38px;
	letter-spacing: 0;
	text-align: left;
	color: #1D84B5;

	@media (max-width: 720px) {
		font-size: 32px;
		font-weight: 700;
		line-height: 38px;
		letter-spacing: 0;
		text-align: center;


	}
}

.paragraph {
	font-family: Helvetica;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0;
	text-align: left;


	@media (max-width: 720px) {
		font-family: Helvetica;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0;
		text-align: center;


	}
}

//NavBar
.hamburger {
	width: 40px;
	height: 37px;
}

//Footer
.footerBox {
	bottom: 0;
	width: 100%;
	padding: 80px 100px 30px 30px;

	@media (max-width: 720px) {
		padding: 80px 100px 30px 30px;
	}
	@media (max-width: 540px) {
		padding: 70px 30px 25px 30px;
	}
}

.footerContainer {
	display: flex;
	justify-content: space-evenly;;
	flex-direction: row;
	max-width: 1000px;
	margin: 0 auto;

	@media (max-width: 540px) {
		flex-direction: column;
		display: flex;
		justify-content: center;

	}


}

// .footerLogo {
// 	@media (min-width: 960px) {
// 		padding-top: 50px;

// 	}

// }

.footerColumn {
	display: flex;
	flex-direction: column;
	text-align: left;

	@media (max-width: 720px) {
		padding-bottom: 5px;
	}

	@media (max-width: 540px) {
		display: flex;
		align-items: center;
	}
}

.footerRow {
	display: flex;
	grid-template-columns: repeat(auto-fill,
					minmax(185px, 1fr));
	grid-gap: 52px;

	@media (max-width: 720px) {
		display: flex;
		justify-content: space-between;

	}
	@media (max-width: 540px) {
		display: block;
		//padding-top: 20px;
	}
}


.headerStyle {

	font-family: Amiko;
	font-size: 46px;
	font-weight: 600;
	line-height: 55px;
	letter-spacing: 0;
	text-align: left;


	@media (max-width: 720px) {
		font-family: Amiko;
		font-size: 34px;
		font-weight: 600;
		line-height: 43px;
		letter-spacing: 0;
		text-align: center;

	}

	@media (max-width: 540px) {
		font-family: Amiko;
		font-size: 32px;
		font-weight: 600;
		line-height: 43px;
		letter-spacing: 0;
		text-align: center;

	}
}

.footerLink {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0;
	text-align: left;

	&:hover {
		color: green;
		transition: 200ms ease-in;
	}

	@media (max-width: 720px) {
		padding-bottom: 10px;
	}

	@media (max-width: 540px) {
		text-align: center;
	}
}

.footerHeading {
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: 0;
	text-align: left;

	@media (max-width: 540px) {
		text-align: center;
		padding-top: 20px;
	}
}

.footerCopyRight {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0;
	text-align: center;
	display: flex;
	justify-content: center;

}

.footerStyling {
	width: 100%;
	@media (max-width: 540px) {
		width: 50%;
	}
}

@import url("https://fonts.googleapis.com/css?family=Amiko");
@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans");

.circular--square {
	border-radius: 50%;
}

.btn-primary {
	color: #F9F9F9;
	background-color: #9CA860;
	border-color: #9CA860;
	border-radius: 0;
}

.btn-secondary {
	color: #F9F9F9;
	background-color: #1D84B5;
	border-color: #1D84B5;
	border-radius: 0;
}

.btn-show-all {
	//width: 138px;
	height: 41px;
	padding: 12px 21px;
	gap: 8px;

	// font-family: Inter;
	font-size: 14px;
	//font-weight: 500;
	line-height: 17px;
	letter-spacing: 0;
	text-align: left;
}

.btn-tertiary {
	color: #F9F9F9;
	width: 380px;
	background-color: #952168;
	border-color: #952168;
	border-radius: 0;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	//.btn-primary:active,
	color: #fff;
	background-color: lighten(#952267, 7.5%) !important;
	border-color: lighten(#952267, 10%) !important; /*set the color you want here*/
	box-shadow: none !important;
}

.btn-primary:disabled {
	color: #fff;
	background-color: lighten(#952267, 40%);
	border-color: lighten(#952267, 45%); /*set the color you want here*/
}

.leaflet-div-icon {
	background: transparent;
	border: none;
}

.shadow {
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
	/* Similar syntax to box-shadow */
}

.dashboard-entity {
	border-radius: 10%;

	display: block;
	margin: 0 auto;

}

.dashboard-entitycaption {

	text-align: center;
}


// dashboard map legend
.info {
	padding: 6px 8px;
	font: 14px/16px Arial, Helvetica, sans-serif;
	background: white;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}

.info h4 {
	margin: 0 0 5px;
	color: #777;
}

.legend {
	text-align: left;
	line-height: 18px;
	color: #555;
}

.legend i {
	width: 18px;
	height: 18px;
	float: left;
	margin-right: 8px;
	opacity: 0.7;
}

.link-button-blue {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	margin: 0;
	padding: 0;
	color: #0078A8;
}

.link-button-blue:hover,
.link-button-blue:focus {
	text-decoration: none;
}

.no-focus:hover, .no-focus:active, .no-focus:focus {
	text-decoration: none;
	outline: none;
}

.joinButton{
	width: 75%;
	max-width: 380px;
}

//// Here is Carousel


.slick-slide {
	transform: scale(0.8);
	transition: all 0.4s ease-in-out;
	padding: 40px 0;
	@media (min-width: 720px) {
		//max-width: 100vw;
		width: 300px;
	}
}

.slider-wrap{
	max-width: 90vw;
	width: 100%;

	@media (max-width: 720px) {
		max-width: 100vw;
		width: 388px;
	}
	@media (max-width: 540px) {
		max-width: 100vw;
		width: 300px;
	}

}

.slick-slide img {
	max-width: 100%;
	transition: all 0.4s ease-in-out;
}

.slick-center {
	transform: scale(1.1);
}

.complete-wrap{
	display: flex;
	width: 100%;
	padding-bottom: 50px;
	align-items: center;
	justify-content: space-between;
}

.slick-arrow{
	display: none;
}

.custom-arrow{
	padding: 0 10px;

	@media (max-width: 540px) {
		visibility: hidden;
	}
}

.slick-slider{
	width: 100%
}

.slider-image{
	width: 100%;
	height: 100%;
	object-fit: cover;

	&:hover {
		opacity: 0.2;
	}
}

.slide-text-container{
	position: absolute;
	top: 0; bottom: 0; right: 0; left: 0;
	opacity: 0;
	background: black;
	color: white;
	transition: 0.4s ease-out;

}

.slide-text-container:hover {
	opacity: 0.6;
}

.item {
	position: relative;
	width: 400px;
	height: 400px;
	cursor: pointer;

	@media (max-width: 720px) {
		width: 388px;
	}
}

.slider-text{
	font-family: Amiko;
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	letter-spacing: 0;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}


.container {
	@media (min-width: 721px)and (max-width:800px){
		max-width: 100%;
	}
}

// Navbar

.navbar-nav .nav-item a {
	color: black;
	font-family: Amiko;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0;
	text-align: left;
	gap: 20px;

}

.nav-item{
	padding-left: 10px;
}
.sign-in-button{
	background: #952168;
	color: white !important;
	width: 90.8px;
	height: 33px;
	padding: 8px 0 8px 21px !important;
	gap: 8px;

}
