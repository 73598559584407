.wrapper {
	display: grid;
	grid-template-columns: 1fr 9fr;
}

.wrapper p {
	margin: 0;
	padding: 5px;
}

.listColumns {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
}

.listColumnsWithHistory {
	display: grid;
	grid-template-columns: 1fr 2fr 0.5fr 0.75fr 0.75fr 0.5fr 0.5fr; /*repeat(5, 1fr);*/
}

.listColumnsWithoutHistory {
	display: grid;
	grid-template-columns: 1fr 2fr 1fr 1fr; /*repeat(4, 1fr);*/
}

.listColumnsRequests {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.listColumnsUser {
	display: flex;
}

.listRows {
	border: 1px solid #000;
	list-style-type: none;
	padding-left: 0px;
}

.listRows li {
	cursor: pointer;
}

.listRows li p {
    color: #000;
    margin: 2px
}

.listRows li:nth-child(odd) {
	background-color: lightgrey;
}

.listRows li:hover {
	background: grey;
	color: #fff;
}

.listRows li:hover p {
	color: #fff;
}

.users {
	flex: 3;
}

.headings p {
	font-weight: bold;
}

.details {
	flex: 1;
	background: grey;
	padding: 10px 20px;
}

.listColumnsProjects {
	display: flex;
}

.listColumnsProjects p {
	margin-right: 50px;
}

.sideTabs {
	margin-top: 50px;
	padding-right: 25px;
}

.sideTabs p {
	padding: 10px;
}

.activeSideTab {
	background-color: lightgrey;
}

.details .imgOrgContainer {
	display: flex;
	align-items: center;
}

.details .img {
	background: white;
	height: 50px;
	width: 50px;
	display: block;
	border-radius: 50%;
	overflow: hidden;
}

.details .orgContainer {
}

.details .infoContainer p {
	padding: 0;
}

.details .disabled {
	opacity: 0.5;
}

.details .disableButton {
	color: red;
}

.details .activateButton {
	color: blue;
}
